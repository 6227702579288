import React from 'react';
import swappingDinamic from "../../Assets/images/generated-tmt.png";
import pool from "../../Assets/images/generated-usdt.png";
import amm from "../../Assets/images/generated-tmt.png";
import graph from '../../Assets/images/graph2.svg';
import graph2 from '../../Assets/images/graph3.svg';
import graph3 from '../../Assets/images/graph2.svg'
import '../Analytics/Analytics.css'
import { useSelector } from '../../redux/store';
import { formatEther } from 'ethers/lib/utils';

function Analytics() {

    const { isStaked, returned, nftPriceAtStaked, returnedUSDT } = useSelector((state) => state.stake)

    return (
        isStaked && <section className="boxsmain2 adminReportTable ">

            <div className="os_container">
                <div className=" text-center text-4xl md:text-5xl mb-5 font-semibold titlebox  blinkers mt-10 ">
                    <h2 className=' anltycs'>Stake Details</h2></div>
                <div className="os_row2 ">
                    <div className="col4 ">
                        <div className="cardbox2 ">
                            <div className="iconbox"><img src={swappingDinamic} className='' alt="" /></div>
                            <p>
                                Nft Staked Price
                                <br /> {nftPriceAtStaked} USDT
                            </p>
                            <img className="vtn-card-img w-[100%]" src={graph} alt="icon" ></img>
                        </div>
                    </div>
                    <div className="col4">
                        <div className="cardbox2 ">
                            <div className="iconbox"><img src={pool} alt="" /></div>

                            <p>
                                Claimed Amount (USDT)
                                <br />{formatEther(returnedUSDT)} USDT
                            </p>
                            <img className="vtn-card-img w-[100%]" src={graph2} alt="icon" ></img>
                        </div>
                    </div>
                    <div className="col4">
                        <div className="cardbox2 ">
                            <div className="iconbox"><img src={amm} alt="" /></div>

                            <p>Claimed Amount (TMT)
                                <br />{formatEther(returned)} TMT
                            </p>

                            <img className="vtn-card-img w-[100%]" src={graph3} alt="icon" ></img>
                        </div>
                    </div>

                </div>
            </div>

        </section>
    );
}

export default Analytics;
