import React, { useCallback } from "react";
import { CContainer, CButton, CRow, CCol } from "@coreui/react";
import banner from "../Assets/images/logocoinvideo.mp4";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { registerToken, setupNetwork } from "../utils/setupNetwork";
import useActiveWeb3React from "../hooks/useWeb3";

import { mglxContract, usdcContract, usdtContract } from "../config";
import { Balance } from "./Balance";
import Nfttoggle from "./Nfttoggle";
import ReactPlayer from "react-player";


const Banner = () => {
  const { library } = useActiveWeb3React();

  const addMainnet = useCallback(() => {
    setupNetwork({ library });
  }, [library]);

  const addToken = useCallback(() => {
    registerToken(mglxContract, "TMT", 18);
  }, []);

  const addUSDT = useCallback(() => {
    registerToken(
      usdtContract,
      "USDT",
      6,
      "https://polygonscan.com/token/images/tether_32.png"
    );
  }, []);

  const Completionist = () => (
    <div className="countDownMu">Staking is LIVE Now!!</div>
  );

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist />;
    } else {
      return (
        <div className="countDownMu">
          <b>
            {hours} <span>Hours</span>
          </b>
          :
          <b>
            {minutes} <span>Minutes</span>
          </b>
          :
          <b>
            {seconds} <span>Seconds</span>
          </b>
          <p>
            NFT Staking Begins on 04/04/2023 <span>12:30 PM (GMT)</span>
          </p>
        </div>
      );
    }
  };

  return (
    <>
      <ToastContainer />
      <section className="banner">
        <CContainer>
          <div className="banner-area">
            <CRow
              xs={{ gutter: 4 }}
              className=" flex-row-reverse align-items-center"
            >
              {/* <ReactPlayer
                  className="color-changing-element"
                      url={banner}
                      width="500"
                      height="600"
                      muted
                      loop
                      playing="true"
                      playsinline="true"
                      /> */}
              {/* <CCol sm={12} md={6} className="text-center">
                <img src={banner} alt="banner" />
              </CCol> */}
              <CCol sm={12} md={12}>
                <ReactPlayer
                  className="color-changing-element"
                  url={banner}
                  width="500"
                  height="600"
                  muted
                  loop
                  playing="true"
                  playsinline="true"
                />
                <div className="info-group" data-aos="fade-center text-center">
                  <h1 className="mx-auto text-center">
                    Elevate Your Digital Assets with Techmont’s Premier NFT Staking Platform
                  </h1>
                  <p className="mt-4 mx-auto text-center">
                    Techmont proudly introduces its revolutionary NFT Staking Platform on the Polygon Chain, designed to transform how you engage with digital assets. Our platform combines rapid transactions, top-tier security, and unparalleled investment opportunities to redefine decentralized finance (DeFi) for the modern era.
                  </p>

                </div>
                <div className="buttonGroup bannerbtn justify-content-center d-flex flex-sm-row flex-column align-items-sm-start align-items-center mt-5">
                  <button className='tech_btn mr-2' onClick={addMainnet}>
                    <i></i><i></i><span>Add Mainnet</span>
                  </button>
                  <button className='tech_btn' onClick={addToken}>
                    <i></i><i></i><span>Add TMT</span>
                  </button>
                  <button className='tech_btn' onClick={addUSDT}>
                    <i></i><i></i><span>Add USDT</span>
                  </button>
                </div>
              </CCol>
            </CRow>
          </div>

          <Nfttoggle />
        </CContainer>
      </section>

      <section className="nftBalance">
        <Balance />
      </section>
      <section className="informationMU">
        <CContainer>
          <CRow className="align-items-center">
            <CCol md="12">
              <div className="infotxt text-center">
                <h3>Unlocking the Benefits of NFT Staking on Techmont:</h3>
                <p>
                  <span>Ultra-Fast Transactions:</span> Techmont harnesses the power of Polygon's Layer 2 scaling solution, ensuring lightning-fast and cost-efficient transactions. Say goodbye to high gas fees and slow processing times, and enjoy a seamless staking experience.
                </p>
                <p>
                  <span>Unmatched Security and Decentralization:</span> Our NFT staking platform is built on Polygon’s secure and decentralized infrastructure. With complete control over your assets and the protection of smart contracts, you can stake with confidence, knowing that rewards distribution is fair and transparent.
                </p>
                <p>
                  <span>Diversify and Strengthen Your Portfolio:</span> Techmont’s NFT staking allows you to earn rewards in the form of cryptocurrency or tokens while enhancing the value of your NFTs. This additional income stream helps mitigate market volatility and strengthens your financial stability.
                </p>
              </div>
            </CCol>
          </CRow>
        </CContainer>
      </section>
    </>
  );
};

export default Banner;
