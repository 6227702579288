import React, { useEffect } from "react";
import { useState } from "react";
import logo from "../Assets/images/logo.png";
import logoname from "../Assets/images/logo.png";
import logotext from "../Assets/images/logotext.svg";


import "../Assets/css/header.css";
import useAuth from '../hooks/useAuth';
import { ConnectorNames } from "../utils/web3React";
import useActiveWeb3React from "../hooks/useWeb3";

const Header = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const [showHamburger, setHamburger] = useState(false);
  const [TeMoPrice, setTeMoPrice] = useState();
  const { login } = useAuth()
  const { account } = useActiveWeb3React()

  function Price() {
    try {
      // const data = fetch("https://oc-price-api.onrender.com/oc-price")
      //   .then((res) => res.json())
      //   .then((json) => {
      //   });
      setTeMoPrice(1);
    } catch (error) {
      console.log(error);
    }
  }

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
    setHamburger(!showHamburger);
  };

  useEffect(() => {
    Price();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      Price();
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <header className={`${showNavbar && "openmenu"}`}>
        <div className="container   mx-auto ">
          <div className="row align-items-center  ">
            <div className="headersec">
              <div className="col-6 col-md-2 text-start">
                <div className="logo">
                  <img
                    src={logo}
                    alt="logo"
                    width="140"
                    height="140"
                    className="d-block"
                  />
                </div>
              </div>
              <div className="buttonGroup">
                <button className='tech_btn cnctwlthedBtn' onClick={() => { login(ConnectorNames.Injected) }} disabled={account}>
                  <i></i><i></i>
                  <span>
                    {
                      !account ? 'Connect' : (account.slice(0, 4) + '...' + account.slice(-4))
                    }
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
