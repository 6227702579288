import React from 'react'
import Banner from '../Components/Banner'
import Faqs from '../Components/Faqs/Faqs'
import Helpbtn from '../Components/Helpbutton/Helpbutton/Helpbtn'


const Home = () => {
    return (
        <>
            <Banner />
            <Faqs />
            <Helpbtn />
      
        </>
    )
}

export default Home