import React from 'react';
import helpicon from '../../../Assets/images/help-icon.png';
import '../Helpbutton/helpbtn.css'; // Assuming you have a CSS file for styling

const Helpbtn = () => {
  const helpPageUrl = 'https://your-help-page-url.com'; // Replace with your help page URL

  const handleRedirect = () => {
    window.open(helpPageUrl, '_blank'); // Opens the help page in a new tab
  };

  return (
    <div className="help-button-container">
      {/* <button className="help-button" onClick={handleRedirect}>
        <img src={helpicon} alt="Help Icon" className="help-icon" />
        <span class="tooltip">Need Help?</span>
      </button> */}
    </div>
  );
};

export default Helpbtn;
