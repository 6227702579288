export const stakeContract = "0xbE738c86D5873C373A37a54A3884594359E04131"
export const mglxContract = "0xf1bD5af0629467423Ccc471237742dff21ED3E7f"

export const nftContract = "0xbfe8c37c5bae7ca180acf7e297d7360f431f64be"

export const usdtContract = "0xf1bD5af0629467423Ccc471237742dff21ED3E7f"
export const usdcContract = "0x2791bca1f2de4661ed88a30c99a7a9449aa84174"

export const tokenID = "1"
export const openSeaUrl = "https://opensea.io/assets/matic/0xbfe8c37c5bae7ca180acf7e297d7360f431f64be/1"
export const multiCallAddress = "0x4445286592CaADEB59917f16826B964C3e7B2D36" //"0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B"


export const BASE_BSC_SCAN_URLS = 'https://polygonscan.com'
export const BASE_URL = 'https://techmontnft.netlify.app/'
export const chainRPC = "https://polygon-rpc.com/"

export const networkName = "Polygon Mainnet"
export const minWithdraw = 10

export const maxCap = 2713.33
export const defaultNetwork = 137