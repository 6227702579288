import { createAsyncThunk } from "@reduxjs/toolkit";
import { ethers } from "ethers";
import { stakeContract } from "../../config";
import stakeABI from "../../config/abis/stakeABI.json"
import { formatNumber } from "../../utils";
import { getContract } from "../../utils/getContract";
import { formatEther, parseEther } from "ethers/lib/utils";

export const fetchStakeInfo = createAsyncThunk('stake/fetchStakeInfo', async (address) => {
    try {
        const stakeInstance = getContract(stakeContract, stakeABI)
        const userData = await stakeInstance.userDatas(address)
        const generated = await stakeInstance.getGenerated(address)

        const perNft = await stakeInstance.getTotalReturnInTMTC(address)
        const dwPrice = await stakeInstance.getTMTPrice()
        const totalDays = await stakeInstance.totalDays()

        return {
            depositedAt: userData.deposited.toNumber(),
            generated: generated.toJSON(),
            lastWithdrawn: userData.lastWithdrawn.toNumber(),
            nftPriceAtStaked: parseFloat(formatEther(userData.nftPriceAtStake)),
            returned: userData.returned.toJSON(),
            returnedUSDT: userData.usdtReturned.toJSON(),
            isStaked: userData.user != ethers.constants.AddressZero,
            maxReturn: parseFloat(formatEther(perNft)),
            dwPrice: parseFloat(formatEther(dwPrice)),
            totalDays: totalDays.toNumber()
        }
    } catch (err) {
        console.debug(err)
        return {
            depositedAt: 0,
            generated: 0,
            lastWithdrawn: 0,
            nftPriceAtStaked: 0,
            returned: 0,
            returnedUSDT: 0,
            generated: false,
            maxReturn: 0,
            dwPrice: 0,
            totalDays: 0
        }
    }
})
