import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './Pages/Layout';
import Home from './Pages/Home';
import About from './Pages/About';
import StarfieldAnimation from 'react-starfield-animation'
import { Buffer } from 'buffer';
import '@coreui/coreui/dist/css/coreui.min.css'
import BigBangStarField from 'react-big-bang-star-field'
import '../src/Assets/css/main.css'
import '../src/Assets/css/_media.scss'

import { Web3ReactProvider } from '@web3-react/core';
import { Provider } from "react-redux"
import store from "./redux/store"
import { Toaster } from "react-hot-toast";

import 'sweetalert2/src/sweetalert2.scss'
import { getLibrary } from './utils/web3React';

import useDirectConnect from './hooks/useDirectConnect'
import loader from '../src/Assets/images/logo.png'


const AppRoutes = () => {
  useDirectConnect()
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="about" element={<About />} />
      </Route>
    </Routes>
  )
}

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate an asynchronous operation (e.g., fetching data) to showcase the loader
    setTimeout(() => {
      setLoading(false);
    }, 2000); // Set a timeout of 2000 milliseconds (adjust as needed)
  }, []);
  window.Buffer = Buffer
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Provider store={store}>
        <BrowserRouter>
        {loading ? (
            // Display the loader while loading is true
            <div className="loader-container">
      <span class="loader">
      <img  src={loader} ></img>

      </span>
          </div>
          ) : (
          <AppRoutes />
          )}
        </BrowserRouter>
        <Toaster />
        {/* <StarfieldAnimation className="bgBody" />
        <StarfieldAnimation className="bgBody" /> */}

        {/* <BigBangStarField
          className="bgBody"
          numStars={70}
          maxStarSpeed={5}
          scale={2}
          starColor={"255, 255, 255"}
        /> */}
      </Provider>
    </Web3ReactProvider>
  );
}

export default App;
